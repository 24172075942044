import React, { useContext, useEffect, useRef } from "react";

import { LsbFormControl } from "../../form-components/LsbFormControl";
import {
  citizenshipDataSchema,
  CompletionContext,
} from "../../contexts/CompletionContext";
import { useFormContext } from "react-hook-form";
import { sanitizedString } from "../../helpers/SanitizedString";
import { FormBlock } from "../../../styles/ApplicationFormComponents";
import ComboboxMultiple from "../../form-components/Combobox/ComboboxMultiple";
import { US_COUNTRY_CODES } from "../validationSchemaCitizenshipStep";

export const CoApplicantCitizenshipFormControls = props => {
  const { inReview } = props;

  const { watch } = useFormContext();

  const meRef = useRef(false);

  useEffect(() => {
    if (!meRef.current) {
      meRef.current = true;
    }
  }, []);

  const { completionData, interfaceText, countries } =
    useContext(CompletionContext);

  const citizenshipDefaultValue =
    completionData?.coApplicant.citizenship?.countries ?? [];

  const citizenship = watch(
    citizenshipDataSchema.coApplicant.citizenship.countries,
    []
  );

  const usCountry = countries?.citizenshipCountryList.find(
    item => item.countrycode === "US"
  );

  return (
    <div style={{ width: "100%" }}>
      <ComboboxMultiple
        name={citizenshipDataSchema.coApplicant.citizenship.countries}
        defaultValue={citizenshipDefaultValue}
        label={sanitizedString(interfaceText?.coApplicantCitizenshipLabel)}
        disabled={inReview}
        helpText={
          interfaceText?.coApplicantCitizenshipHelp &&
          !inReview &&
          sanitizedString(interfaceText?.coApplicantCitizenshipHelp)
        }
        options={countries.citizenshipCountryList}
      />

      {citizenship.filter(value => US_COUNTRY_CODES.includes(value)).length >
        0 && (
        <FormBlock fullwidth='full-width' review={inReview && "inReview"}>
          <LsbFormControl
            maxLength={11}
            formatType='common'
            disabled={inReview}
            label={sanitizedString(interfaceText?.coApplicantUsCitizenTaxID)}
            name={citizenshipDataSchema.coApplicant.usTaxID}
            placeholder={interfaceText?.coApplicantUsCitizenTaxIDPlaceholder}
            defaultValue={completionData?.coApplicant.usTaxID}
            helpText={
              !inReview &&
              sanitizedString(interfaceText?.coApplicantUsCitizenTaxIDHelp)
            }
            icon={usCountry.icon}
          />
        </FormBlock>
      )}
    </div>
  );
};
