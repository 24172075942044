import { IconButton, styled } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const DropDown = styled(IconButton)(() => ({
  position: "absolute",
  right: 0,
  top: "50%",
  transform: "translate(0px, -50%)",
  color: "#6b706e",
  cursor: "pointer",

  "&:hover": {
    background: "transparent",
  },
}));

export const ComboboxDropdown = ({ onClick, open, disabled }) => {
  return (
    <DropDown
      type='button'
      onClick={onClick}
      size='small'
      focusRipple={true}
      disabled={disabled}
    >
      {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </DropDown>
  );
};
