import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import { ComboboxTextfield } from "./ComboboxTextField";
import { Controller, useFormContext } from "react-hook-form";
import { ComboboxMenuItem, ComboboxMenuItemName } from "./ComboboxMenuItem";
import CheckIcon from "@mui/icons-material/Check";

export default function ComboboxMultiple(props) {
  const [open, setOpen] = useState(false);
  const { control, formState } = useFormContext();

  const toggleList = () => {
    setOpen(!open);
  };

  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue={props.defaultValue}
      render={({ field: { onChange, value, name } }) => {
        const valueObjects = props.options.filter(item =>
          value.includes(item.countrycode)
        );

        return (
          <Autocomplete
            multiple={true}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onChange={(e, value) => {
              const codes = value ? value.map(item => item.countrycode) : [];

              onChange(codes);
            }}
            disabled={props.disabled}
            value={valueObjects}
            isOptionEqualToValue={(option, value) =>
              option.countrycode === value.countrycode
            }
            getOptionLabel={option => option.name}
            options={props?.options ?? []}
            noOptionsText='Ingen namn hittats.'
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;

              return (
                <ComboboxMenuItem
                  key={key}
                  {...optionProps}
                  value={option.countrycode}
                >
                  <ComboboxMenuItemName>
                    <img src={option.icon} alt='img' />
                    {option.name}
                  </ComboboxMenuItemName>

                  {optionProps["aria-selected"] && (
                    <CheckIcon fontSize='16' color='primary' />
                  )}
                </ComboboxMenuItem>
              );
            }}
            renderInput={params => (
              <ComboboxTextfield
                {...params}
                name={name}
                label={props.label}
                disabled={props.disabled}
                placeholder={valueObjects.length > 0 ? "" : "Sök landets namn"}
                open={open}
                errors={formState.errors}
                helpText={props.helpText}
                toggleList={toggleList}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        );
      }}
    />
  );
}
