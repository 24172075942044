import { yupResolver } from "@hookform/resolvers/yup";

import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";

import * as Scroll from "react-scroll";

import { Fade, Grid } from "@mui/material";

import { Section } from "../Layout/Section";
import { IngressTextCard } from "../HelpText/IngressText/IngressTextCard";
import { SanitizedHtml } from "../helpers/SanitizedHtml";
import { IngressTextLink } from "../HelpText/IngressText/IngressTextLink";

import { CompletionContext } from "../contexts/CompletionContext";
import { sanitizedString } from "../helpers/SanitizedString";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { CitizenshipFormControls } from "./components/CitizenshipFormControls";
import { CoApplicantCitizenship } from "./coApplicant/CoApplicantCitizenship";
import { useUpdateCompletionData } from "../../graphql/useUpdateCompletionData";
import {
  citizenshipValidationSchema,
  tinRegEx,
  US_COUNTRY_CODES,
} from "./validationSchemaCitizenshipStep";
import { StepHeading } from "../misc/Typography/StepHeading";
import { StepPreamble } from "../misc/Typography/StepPreamble";
import { StepContent } from "../Layout/StepContent";
import { HelpTooltip } from "../HelpTooltip/HelpTooltip";
import { Spinner } from "../misc/Spinner/Spinner";
import { Name } from "../misc/Typography/Name";
import { StepSectionPaddingTop } from "../../styles/ApplicationFormComponents";

export const CitizenshipStep = props => {
  const {
    stepIndex,
    disableCheckStep,
    checkCompleted,
    handleMove,
    setDisableMove,
  } = props;

  const [fadeIn, setfadeIn] = useState(false);

  const [completed, setCompleted] = useState(false);

  const {
    handleStepsCompleted,
    stepsCompleted,
    completionData,
    interfaceText,
    completionInput,
  } = useContext(CompletionContext);

  const hasCoApplicant = completionData?.hasCoApplicant ?? false;

  const scroll = Scroll.animateScroll;

  const [openIngressText, setOpenIngressText] = useState(false);

  const [loading, setLoading] = useState(false);

  const updateCompletionData = useUpdateCompletionData();

  const applicantPersonalData = completionData?.extraInformation ?? null;
  const coApplicantPersonalData =
    completionData?.coApplicant?.extraInformation ?? null;

  const stepRef = useRef(false);

  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(citizenshipValidationSchema),
  });

  const { watch, control, formState } = formMethods;

  const citizenship = watch(
    "citizenship.countries",
    completionData?.citizenship?.countries ?? []
  );

  const usTaxID = watch("usTaxID", completionData?.usTaxID ?? "");

  const coCitizenship = watch(
    "coApplicant.citizenship.countries",
    completionData?.coApplicant?.citizenship?.countries ?? []
  );

  const coUsTaxID = watch(
    "coApplicant.usTaxID",
    completionData?.coApplicant?.usTaxID ?? ""
  );

  useEffect(() => {
    const isApplicantDataValid = () => {
      const usCitizen =
        citizenship.filter(value => US_COUNTRY_CODES.includes(value)).length >
        0;
      if (citizenship.length !== 0) {
        if (usCitizen) {
          if (!tinRegEx.test(usTaxID)) {
            return false;
          }
        }

        return true;
      }

      return false;
    };

    const isCoApplicantDataValid = () => {
      const coUsCitizen =
        coCitizenship.filter(value => US_COUNTRY_CODES.includes(value)).length >
        0;
      if (coCitizenship.length !== 0) {
        if (coUsCitizen) {
          if (!tinRegEx.test(coUsTaxID)) {
            return false;
          }
        }

        return true;
      }

      return false;
    };

    if (!hasCoApplicant) {
      setCompleted(isApplicantDataValid());
    } else {
      setCompleted(isApplicantDataValid() && isCoApplicantDataValid());
    }
  }, [
    citizenship,
    coCitizenship,
    usTaxID,
    coUsTaxID,
    hasCoApplicant,
    formState,
  ]);

  useEffect(() => {
    if (stepRef.current) return;

    stepRef.current = true;

    if (interfaceText) {
      setfadeIn(props.fadeIn);
    }

    disableCheckStep();

    return () => {
      stepRef.current = false;
    };
  }, [props.fadeIn, disableCheckStep, interfaceText]);

  useEffect(() => {
    if (stepRef.current === false) return;
    handleStepsCompleted(stepIndex, completed);
  }, [completed, handleStepsCompleted, stepIndex]);

  const sendCompletionData = useCallback(
    async data => {
      scroll.scrollToTop({
        smooth: true,
        duration: 100,
      });

      try {
        setLoading(true);
        setDisableMove(true);

        const res = await updateCompletionData({
          variables: {
            input: data,
          },
        });

        if (!res?.data?.errors) {
          handleMove();
          setLoading(false);
        }
      } catch (error) {
      } finally {
        setLoading(false);
        setDisableMove(false);
      }
    },

    [updateCompletionData, handleMove, scroll, setDisableMove]
  );

  useEffect(() => {
    if (checkCompleted && stepsCompleted[stepIndex]) {
      const taxesInput = formMethods.getValues();
      const applicantUS =
        taxesInput?.citizenship?.countries &&
        taxesInput?.citizenship.countries.filter(country =>
          US_COUNTRY_CODES.includes(country)
        ).length > 0;

      const coApplicantUs =
        taxesInput?.coApplicant?.citizenship?.countries &&
        taxesInput?.coApplicant.citizenship.countries.filter(country =>
          US_COUNTRY_CODES.includes(country)
        ).length > 0;

      const input = {
        ...completionInput,
        citizenship: taxesInput.citizenship,
        usTaxID:
          applicantUS && taxesInput?.usTaxID ? taxesInput?.usTaxID : null,
        usCitizen: applicantUS,
        coApplicant: hasCoApplicant
          ? {
              ...completionInput.coApplicant,
              citizenship: taxesInput?.coApplicant?.citizenship,
              usTaxID:
                coApplicantUs && taxesInput?.coApplicant?.usTaxID
                  ? taxesInput?.coApplicant?.usTaxID
                  : null,
              usCitizen: coApplicantUs,
            }
          : null,
      };

      sendCompletionData(input);
    }
  }, [
    checkCompleted,
    stepIndex,
    stepsCompleted,
    formMethods,
    completionData,
    sendCompletionData,
    hasCoApplicant,
    completionInput,
  ]);

  function handleOpenIngressText() {
    setOpenIngressText(true);
  }

  return (
    <Fade in={fadeIn} timeout={{ enter: 500, exit: 500 }}>
      <div>
        <StepContent>
          {loading && <Spinner overlay />}

          <Section>
            <Grid item xs={12}>
              <StepHeading
                title={<SanitizedHtml html={interfaceText?.citizenshipTitle} />}
                helpText={
                  interfaceText?.citizenshipHelp && (
                    <HelpTooltip
                      text={sanitizedString(interfaceText?.citizenshipHelp)}
                    />
                  )
                }
              />
            </Grid>
            <Grid item sm={8}>
              <StepPreamble>
                <SanitizedHtml html={interfaceText?.citizenshipDescription} />

                <IngressTextLink
                  handleOpenIngressText={handleOpenIngressText}
                />
              </StepPreamble>
            </Grid>

            {openIngressText && (
              <Grid item sm={8}>
                <IngressTextCard
                  text={interfaceText?.citizenshipIngress}
                  openIngressText={openIngressText}
                  closeIngressText={setOpenIngressText}
                />
              </Grid>
            )}
          </Section>
          <FormProvider {...formMethods}>
            <form>
              <StepSectionPaddingTop>
                <Controller
                  control={control}
                  name='hasCoApplicant'
                  defaultValue={hasCoApplicant}
                  render={() => {
                    return null;
                  }}
                />
                <Section>
                  {applicantPersonalData && (
                    <Grid item xs={12}>
                      <Name personalData={applicantPersonalData} />
                    </Grid>
                  )}
                  <CitizenshipFormControls />
                </Section>
              </StepSectionPaddingTop>

              {hasCoApplicant && (
                <CoApplicantCitizenship
                  personalData={coApplicantPersonalData}
                />
              )}
            </form>
          </FormProvider>
        </StepContent>
      </div>
    </Fade>
  );
};
