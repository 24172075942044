import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { TaxesFormComponent } from "./components/TaxesFormComponent";
import { CoApplicantTaxesFormComponent } from "./coApplicant/components/CoApplicantTaxesFormComponent";
import { otherCountriesValidationSchema } from "./validationSchemaOtherCountriesTaxesStep";

export const OtherCountriesTaxesForm = props => {
  const {
    otherTaxResidences,
    hasCoApplicant,
    setCompleted,
    submitForm,
    onSubmit,
    applicantPersonalData,
    coApplicantPersonalData,
  } = props;

  const formRef = useRef(false);

  const [isOtherCountriesTaxesValid, setIsOtherCountriesTaxesValid] =
    useState(false);

  const [isCoAppOtherCountriesTaxesValid, setCoAppIsOtherCountriesTaxesValid] =
    useState(false);

  const newTax = { countrycode: null, taxID: "", id: "" };

  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(otherCountriesValidationSchema),
  });

  const { handleSubmit, control, reset, getValues, watch } = formMethods;

  const otherTaxResidencesArray = useFieldArray({
    control,
    name: "otherTaxResidences",
    keyName: "idn",
  });

  const coApplicantTaxResidencesArray = useFieldArray({
    control,
    name: "coApplicant.otherTaxResidences",
    keyName: "idn",
  });

  const hasOtherTaxResidences = watch("hasOtherTaxResidences", null);
  const coAppHasOtherTaxResidences = watch(
    "coApplicant.hasOtherTaxResidences",
    null
  );

  function addTaxField() {
    otherTaxResidencesArray.append(newTax);
  }

  function addCoAppTaxField() {
    coApplicantTaxResidencesArray.append(newTax);
  }

  function clearOtherTaxResidencesArray() {
    otherTaxResidencesArray.remove();
  }

  function clearCoOtherTaxResidencesArray() {
    coApplicantTaxResidencesArray.remove();
  }

  function onRemoveTaxField(index) {
    if (otherTaxResidencesArray.fields.length === 1) {
      reset({
        ...getValues(),
        hasOtherTaxResidences: "sweden_only",
      });
    }
    otherTaxResidencesArray.remove(index);
  }

  function onRemoveCoApplicantTaxField(index) {
    if (coApplicantTaxResidencesArray.fields.length === 1) {
      reset({
        ...getValues(),
        coApplicant: {
          hasOtherTaxResidences: "sweden_only",
        },
      });
    }
    coApplicantTaxResidencesArray.remove(index);
  }

  useLayoutEffect(() => {
    const isApplicantDataValid =
      hasOtherTaxResidences === "sweden_only" ||
      (hasOtherTaxResidences === "other_countries" &&
        isOtherCountriesTaxesValid);

    if (hasCoApplicant === false) {
      setCompleted(isApplicantDataValid);
    } else {
      const isCoApplicantDataValid =
        coAppHasOtherTaxResidences === "sweden_only" ||
        (coAppHasOtherTaxResidences === "other_countries" &&
          isCoAppOtherCountriesTaxesValid);

      setCompleted(isApplicantDataValid && isCoApplicantDataValid);
    }
  }, [
    coAppHasOtherTaxResidences,
    isCoAppOtherCountriesTaxesValid,
    hasCoApplicant,
    hasOtherTaxResidences,
    isOtherCountriesTaxesValid,
    setCompleted,
  ]);

  useEffect(() => {
    if (!formRef.current && otherTaxResidences.otherTaxResidences.length > 0) {
      reset(
        {
          ...getValues(),
          otherTaxResidences: otherTaxResidences.otherTaxResidences,
        },
        { touched: true, isDirty: true, errors: false, isValid: true }
      );

      if (!hasCoApplicant) {
        setCompleted(true);
      }
    }

    if (
      !formRef.current &&
      hasCoApplicant &&
      otherTaxResidences.coApplicant.otherTaxResidences.length > 0
    ) {
      reset(
        {
          ...getValues(),
          coApplicant: {
            otherTaxResidences:
              otherTaxResidences.coApplicant.otherTaxResidences,
          },
          otherTaxResidences: otherTaxResidences.otherTaxResidences,
        },
        { touched: true, isDirty: true, errors: false, isValid: true }
      );
      setCompleted(true);
    }

    formRef.current = true;
  }, [otherTaxResidences, reset, getValues, setCompleted, hasCoApplicant]);

  useEffect(() => {
    if (submitForm) {
      handleSubmit(onSubmit)();
    }
  }, [handleSubmit, onSubmit, submitForm]);

  return (
    <FormProvider {...formMethods}>
      <form style={{ width: "100%" }}>
        <Controller
          control={control}
          name='hasCoApplicant'
          defaultValue={hasCoApplicant}
          render={() => {
            return null;
          }}
        />

        <TaxesFormComponent
          addTaxField={addTaxField}
          removeTaxFields={clearOtherTaxResidencesArray}
          fields={otherTaxResidencesArray.fields}
          isOtherCountriesTaxesValid={setIsOtherCountriesTaxesValid}
          onRemoveTaxField={onRemoveTaxField}
          personalData={applicantPersonalData}
        />

        {hasCoApplicant && (
          <CoApplicantTaxesFormComponent
            addTaxField={addCoAppTaxField}
            removeTaxFields={clearCoOtherTaxResidencesArray}
            fields={coApplicantTaxResidencesArray.fields}
            isCoAppOtherCountriesTaxesValid={setCoAppIsOtherCountriesTaxesValid}
            onRemoveTaxField={onRemoveCoApplicantTaxField}
            personalData={coApplicantPersonalData}
          />
        )}
      </form>
    </FormProvider>
  );
};
