import { MenuItem, styled } from "@mui/material";

export const ComboboxMenuItem = styled(MenuItem)(() => ({
  paddingTop: 0,
  paddingBottom: 0,
  display: "flex",
  gap: "12px",

  "&.MuiAutocomplete-option": {
    justifyContent: "space-between",
  },
}));

export const ComboboxMenuItemName = styled("div")(() => ({
  display: "flex",
  gap: "12px",
  "& img": {
    width: "24px",
    height: "auto",
    borderRadius: "2px",
    marginRight: 0,
  },
}));
