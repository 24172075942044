import * as yup from "yup";

export const otherCountriesValidationSchema = yup.object().shape({
  hasOtherTaxResidences: yup
    .string()
    .required()
    .typeError("Vänligen välj alternativ"),
  otherTaxResidences: yup.array(
    yup.object({
      id: yup.string().nullable(),
      countrycode: yup.string().required("Vänligen välj alternativ."),
      taxID: yup.string().when("countrycode", {
        is: value => value !== "",
        then: schema => schema.required("Vänligen ange TIN."),
        otherwise: schema => schema.notRequired(),
      }),
    })
  ),
  hasCoApplicant: yup.boolean(),
  coApplicant: yup.object({}).when("hasCoApplicant", {
    is: true,
    then: schema =>
      schema.shape({
        hasOtherTaxResidences: yup.string().required(),
        otherTaxResidences: yup.array(
          yup.object({
            id: yup.string().nullable(),
            countrycode: yup.string().required("Vänligen välj alternativ."),
            taxID: yup.string().when("countrycode", {
              is: value => value !== "",
              then: schema => schema.required(),
              otherwise: schema => schema.notRequired(),
            }),
          })
        ),
      }),
    otherwise: schema => schema.notRequired(),
  }),
});
