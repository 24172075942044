import { FormControl, FormHelperText, FormLabel, Input } from "@mui/material";
import { HelpTooltip } from "../../HelpTooltip/HelpTooltip";
import { ComboboxDropdown } from "./CompoboxDropdown";
import { ErrorMessage } from "@hookform/error-message";
import _ from "lodash";

export const ComboboxTextfield = props => {
  const hasError = (name, errors) => {
    return _.get(errors, name) !== undefined;
  };

  return (
    <FormControl error={hasError(props.name, props.errors)}>
      <FormLabel
        htmlFor={props.id}
        color='secondary'
        className={props.helpText ? "MuiFormLabel-withHelp" : ""}
      >
        {props.label}
        {props.helpText && <HelpTooltip text={props.helpText} />}
      </FormLabel>
      <Input
        {...props.InputProps}
        inputProps={{ ...props.inputProps }}
        style={{ paddingRight: 0 }}
        id={props.inputId}
        name={props.name}
        placeholder={props.placeholder}
        color='secondary'
        disabled={props.disabled}
        endAdornment={
          <ComboboxDropdown
            onClick={props.toggleList}
            open={props.open}
            disabled={props.disabled}
          />
        }
      />

      <ErrorMessage
        errors={props.errors}
        name={props.name}
        render={({ message }) => (
          <FormHelperText error={true}>
            {message ? message : "Vänligen välj alternativ"}
          </FormHelperText>
        )}
      />
    </FormControl>
  );
};
