import * as yup from "yup";

export const US_COUNTRY_CODES = ["US", "VI", "UM", "PR", "GU", "AS", "MP"];

export const tinRegEx =
  /^(?=[0-9-]{9,11})(?!(.*-){3})(?=(.*\d){9})(?!(.*\d){10})[0-9-]+$/;

export const citizenshipValidationSchema = yup.object().shape({
  citizenship: yup.object().shape({
    countries: yup
      .array()
      .of(yup.string())
      .min(1, "Vänligen välj alternativ.")
      .max(3, "Vänligen välj max 3 alternativ."),
  }),
  usTaxID: yup.string().when("citizenship.countries", {
    is: countries =>
      countries.filter(i => US_COUNTRY_CODES.includes(i)).length > 0,
    then: schema =>
      schema
        .required("Vänligen ange TIN.")
        .matches(tinRegEx, "TIN består av 9 siffror och 1 eller 2 bindestreck.")
        .min(9, "TIN består av 9 siffror och 1 eller 2 bindestreck.")
        .max(11, "TIN består av 9 siffror och 1 eller 2 bindestreck."),
    otherwise: schema => schema.notRequired(),
  }),
  hasCoApplicant: yup.boolean(),
  coApplicant: yup.object({}).when("hasCoApplicant", {
    is: true,
    then: schema =>
      schema.shape({
        citizenship: yup.object().shape({
          countries: yup
            .array()
            .of(yup.string())
            .min(1, "Vänligen välj alternativ.")
            .max(3, "Vänligen välj max 3 alternativ."),
        }),
        usTaxID: yup.string().when("citizenship.countries", {
          is: value =>
            value.filter(i => US_COUNTRY_CODES.includes(i)).length > 0,
          then: schema =>
            schema
              .required()
              .matches(
                tinRegEx,
                "TIN består av 9 siffror och 1 eller 2 bindestreck."
              )
              .min(9, "TIN består av 9 siffror och 1 eller 2 bindestreck.")
              .max(11, "TIN består av 9 siffror och 1 eller 2 bindestreck."),
          otherwise: schema => schema.notRequired(),
        }),
      }),
    otherwise: schema => schema.notRequired(),
  }),
});
