import { formatISO } from "date-fns";
import React from "react";

import { formatDate } from "../../../utils/formatDate";
import { styled } from "@mui/material";

const Time = styled("time")(() => ({
  whiteSpace: "nowrap",
}));

export const FormattedDate = ({ date }) => {
  const formattedDate = formatDate(date, "yyyy-MM-dd");
  const isoDate = formatISO(date);

  return <Time dateTime={isoDate}>{formattedDate}</Time>;
};
