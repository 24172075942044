import { Controller, useFormContext } from "react-hook-form";

import React, { useContext, useEffect } from "react";

import { Grid } from "@mui/material";
import { LsbFormControl } from "../../../form-components/LsbFormControl";
import {
  CompletionContext,
  taxesDataSchema,
} from "../../../contexts/CompletionContext";
import { sanitizedString } from "../../../helpers/SanitizedString";
import { RemoveTaxButton } from "../../RemoveTaxButton";
import { FormBlock } from "../../../../styles/ApplicationFormComponents";
import Combobox from "../../../form-components/Combobox/Combobox";

export const CoApplicantTaxesFormControls = props => {
  const {
    index,
    inReview,
    defaultValues,
    setDisabledAddButton,
    onRemoveTaxField,
  } = props;

  const { interfaceText, countries } = useContext(CompletionContext);

  const { watch } = useFormContext();

  const selectedCountry = watch(
    `coApplicant.otherTaxResidences.${[index]}.${
      taxesDataSchema.otherTaxResidences.countrycode
    }`,
    null
  );

  const field = watch(`coApplicant.otherTaxResidences.${[index]}`, null);

  const taxId = watch(`coApplicant.otherTaxResidences.${[index]}.taxID`, "");

  useEffect(() => {
    if (inReview) {
      return;
    }

    if (field && (selectedCountry === null || taxId === "")) {
      return setDisabledAddButton(true);
    }

    setDisabledAddButton(false);
  }, [field, selectedCountry, taxId, inReview, setDisabledAddButton]);

  return (
    <FormBlock
      fullwidth={!inReview && "full-width"}
      review={inReview && "inReview"}
    >
      {!inReview && (
        <RemoveTaxButton
          onRemove={() => {
            setDisabledAddButton(false);
            onRemoveTaxField(index);
          }}
        />
      )}

      <Grid item xs={12} sm={10} md={8} lg={6}>
        <Controller
          name={`coApplicant.otherTaxResidences.${[index]}.id`}
          defaultValue={defaultValues?.id ?? null}
          render={() => {
            return null;
          }}
        />

        <Combobox
          name={`coApplicant.otherTaxResidences.${[index]}.${
            taxesDataSchema.otherTaxResidences.countrycode
          }`}
          label={sanitizedString(
            interfaceText?.coOtherTaxDomicileCountryListLabel
          )}
          defaultValue={defaultValues?.countrycode}
          disabled={inReview}
          index={index}
          options={countries.otherTaxDomicileCountryList}
          helpText={
            !inReview &&
            sanitizedString(interfaceText?.otherTaxDomicileCountryListHelp)
          }
        />

        <LsbFormControl
          type='common'
          label={sanitizedString(interfaceText?.coOtherTaxDomicileTaxIDLabel)}
          name={`coApplicant.otherTaxResidences.${[index]}.${
            taxesDataSchema.otherTaxResidences.taxID
          }`}
          placeholder={interfaceText?.otherTaxDomicileTaxIDLabelPlaceholder}
          defaultValue={props.defaultValues?.taxID}
          index={props.index}
          disabled={inReview}
          helpText={
            !inReview &&
            sanitizedString(interfaceText?.otherTaxDomicileTaxIDHelp)
          }
        />
      </Grid>
    </FormBlock>
  );
};
